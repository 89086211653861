import BigNumber from 'bignumber.js';
import { BaseRfqLeg } from '#/unified-rfqs/entities/shared';
import { getProduct, findHedgeProduct } from '#/products/domain';
import { parseInstrument } from '#/unified-rfqs/ui/rfqs/legs-functions';
import { isSameDayDate } from '@paradigm/utils/src/date';
import { BaseRfq } from '../shared';
import { Instrument } from './instruments';

export type DrfqKind = 'FUTURE' | 'OPTION' | 'OPTION_FUTURE';

export type BaseCurrency = 'BTC' | 'ETH' | 'SOL';
export type QuoteCurrency = 'BTC' | 'ETH' | 'SOL' | 'USD' | 'USDC';
export type ClearingCurrency = 'BTC' | 'ETH' | 'SOL' | 'USD';

export type Role = 'MAKER' | 'TAKER';

export type DrfqState = 'OPEN' | 'CLOSED' | 'RECENTLY_CLOSED' | 'DRAFT';

export type DrfqVenueCode = 'DBT' | 'BIT' | 'BYB';

export type DrfqClosedReason =
  | 'CANCELED_BY_CREATOR'
  | 'EXPIRED'
  | 'EXECUTION_LIMIT'
  // null if state == OPEN
  | null;

export type MarginKind = 'INVERSE' | 'LINEAR';

export type Label = string;

export interface Drfq extends BaseRfq {
  readonly type: 'drfq';
  /** Null if user is not the creator of DRFQ */
  readonly account_name: string | null;
  readonly id: string;
  readonly kind: DrfqKind;
  readonly role: Role;
  readonly venue: DrfqVenueCode;
  readonly label: Label;
  readonly state: DrfqState;
  readonly closed_reason: DrfqClosedReason;
  readonly strategy_code: string;
  readonly strategy_description: string;
  readonly description: string;
  readonly base_currency: BaseCurrency;
  readonly quote_currency: QuoteCurrency;
  readonly clearing_currency: ClearingCurrency;
  readonly quantity: string;
  /** max number of orders */
  readonly side_layering_limit: number;
  readonly is_taker_anonymous: boolean;
  readonly legs: readonly DrfqLeg[];
  readonly product_codes: readonly string[];
  /** empty list if role == MAKER */
  readonly counterparties: readonly string[];
  readonly last_updated_at: Date;
  readonly created_at: Date;
  readonly expires_at: Date;
  /** Null if role = MAKER and is_taker_anonymous = true */
  readonly taker_desk_name: string | null;
  /**
   * Only present for Prime MAKERS that receives an ANON rfq
   */
  readonly taker_profile?: TakerProfile | null;
}

interface TakerProfile {
  readonly average_taker_fill_rate: string;
}

export interface RawDfrqLeg extends BaseRfqLeg {
  readonly instrument_id: number;
  readonly instrument_name: string;
  readonly quantity: string;
}

export interface DrfqLeg extends RawDfrqLeg {
  readonly isHedge: boolean;
}

export type RfqsEvent = 'ADDED' | 'REMOVED';
export type RfqOrdersEvent = 'ADDED' | 'REMOVED' | 'UPDATED';
export type OrdersEvent = 'NEW' | 'CANCELED' | 'PENDING_FILL' | 'FILLED';
export type TradesEvent = 'PENDING_SETTLEMENT' | 'FILLED' | 'REJECTED';
export type TradeTapeEvent = 'FILLED';
export type BboEvent = 'NEW';
export type MmpEvent = 'TRIGGERED' | 'RESET';

export interface Counterparty {
  readonly firm_name: string;
  readonly desk_name: string;
  readonly venues: readonly DrfqVenueCode[];
  readonly groups?: readonly string[];
  readonly id: number;
}

export const REPRICE_TIMEOUT_MS = 30000;

export function isDrfq(rfq: BaseRfq): rfq is Drfq {
  return rfq.type === 'drfq';
}

export function getDrfqStatusLabel(
  drfq: Drfq,
  isExecuted: boolean,
  isDraft?: boolean,
) {
  if (isDraft) return 'Draft';
  if (drfq.state === 'OPEN') return 'Active';

  if (drfq.closed_reason === 'EXECUTION_LIMIT')
    return isExecuted ? 'Filled' : 'Trd Away';
  if (drfq.closed_reason === 'EXPIRED') return 'Expired';
  if (drfq.closed_reason === 'CANCELED_BY_CREATOR') return 'Canceled';

  return 'Closed';
}

export function isLiquidityProvider(cp: Counterparty): boolean {
  if (cp.groups == null) return false;
  return cp.groups.includes('LP');
}

export function isFutureInstrument(ins: Instrument) {
  return ins.kind === 'FUTURE';
}

export function isOptionInstrument(ins: Instrument) {
  return ins.kind === 'OPTION';
}

export function isTaker(rfq: Pick<Drfq, 'role'>) {
  return rfq.role === 'TAKER';
}

export function isMaker(rfq: Pick<Drfq, 'role'>) {
  return rfq.role === 'MAKER';
}

/**
 * Calculate DRFQ notional
 *
 * - It's the sum of all legs notional
 */
export function calcDrfqNotional(
  rfq: Drfq,
  drfqFutureInstruments: readonly Instrument[],
) {
  const venueInstruments = drfqFutureInstruments.filter(
    (instrument) => instrument.venue === rfq.venue,
  );
  const notional = rfq.legs.reduce((acc, leg) => {
    try {
      const product = getProduct(leg.product_code);

      if (product.kind === 'FUTURE')
        return acc.plus(
          getFutureLegNotional(leg, venueInstruments, product.marginType),
        );

      return acc.plus(getOptionLegNotional(leg, venueInstruments));
    } catch (error) {
      return acc;
    }
  }, BigNumber(0));

  return notional;
}

/**
 * Calculate future leg notional
 *
 * - It's the quantity times the mark price
 * - If margin type is INVERSE, it returns the quantity
 */
function getFutureLegNotional(
  leg: DrfqLeg,
  instruments: Instrument[],
  marginType: 'LINEAR' | 'INVERSE',
) {
  if (marginType === 'INVERSE') return BigNumber(leg.quantity);

  const futureInstrument: Instrument | undefined = instruments.find(
    (instrument) => instrument.id === leg.instrument_id,
  );
  if (futureInstrument == null) return BigNumber(0);
  return BigNumber(futureInstrument.mark_price).times(leg.quantity);
}

/**
 * Calculate option leg notional
 *
 * - It's the FUTURE instrument with same expiry mark price times quantity
 * - If there's no future instrument with same expiry, it uses the perp future mark price
 */
function getOptionLegNotional(leg: DrfqLeg, instruments: Instrument[]) {
  const hedgeProduct = findHedgeProduct({ for: leg.product_code });
  const perpFuture = instruments.find(
    (instrument) =>
      instrument.product_code === hedgeProduct.code &&
      instrument.expires_at == null,
  );
  if (perpFuture == null) return BigNumber(0);

  const legInstrument = parseInstrument(leg.instrument_name);
  const expiryFuture = instruments.find(
    (instrument) =>
      instrument.product_code === hedgeProduct.code &&
      isSameDayDate(instrument.expires_at ?? 0, new Date(legInstrument.expiry)),
  );

  if (expiryFuture == null)
    return BigNumber(leg.quantity).times(perpFuture.mark_price);

  return BigNumber(expiryFuture.mark_price).times(leg.quantity);
}
