import BigNumber from 'bignumber.js';

import memoWeak from '@paradigm/utils/memoWeak';

import {
  GrfqTrade,
  GrfqQuote,
  GrfqTradeTape,
} from '#/unified-rfqs/entities/grfq/domain';
import {
  Trade as DrfqTrade,
  TradeTapeItem as DrfqTradeTapeItem,
} from '#/unified-rfqs/entities/drfq/trades';
import { Order as DrfqOrder } from '#/unified-rfqs/entities/drfq/orders';

export interface BaseRfq {
  id: number | string;
  description: string;
  venue: string;
  type: RfqType;
}

export interface BaseRfqLeg {
  readonly product_code: string;
  readonly ratio: string;
  readonly side: 'BUY' | 'SELL';
  /** Price for Hedge legs */
  readonly price?: string | null;
}

export type TradeDisplayValues = {
  tradePrice: string;
  markPrice: string;
  markOffset: string;
  indexPrice: string;
};

export type RfqType = 'drfq' | 'grfq';

export type AuctionType = 'OB' | 'RFQ';

export type Side = 'BUY' | 'SELL';

export type OptionKind = 'CALL' | 'PUT';

export type QuoteStatus = 'OPEN' | 'CLOSED';

export type OrderType = 'LIMIT' | 'HIDDEN';

export type Trade = GrfqTrade | DrfqTrade;

export type TradeTapeItem = GrfqTradeTape | DrfqTradeTapeItem;

export type Order = GrfqQuote | DrfqOrder;

export type PricingAlgoVersion = 'v2' | undefined;

export function getTradeTime(trade: Trade) {
  switch (trade.rfqType) {
    case 'drfq':
      return trade.executed_at.getTime();
    case 'grfq':
      return trade.traded.getTime();
    // no default
  }
}

export function getTradeTapeItemTime(trade: TradeTapeItem) {
  switch (trade.rfqType) {
    case 'drfq':
      return trade.filled_at.getTime();
    case 'grfq':
      return trade.traded.getTime();
    // no default
  }
}

export function getOrderTime(order: Order) {
  switch (order.rfqType) {
    case 'drfq':
      return order.last_updated_at.getTime();
    case 'grfq':
      return order.last_activity.getTime();
    // no default
  }
}

export function isOpenOrder(order: Order) {
  switch (order.rfqType) {
    case 'drfq':
      return order.state === 'OPEN';
    case 'grfq':
      return order.status === 'OPEN';
    // no default
  }
}
export function isTradeFilled(trade: Trade) {
  switch (trade.rfqType) {
    case 'drfq':
      return trade.state === 'FILLED';
    case 'grfq':
      return trade.status === 'FILLED';
    // no default
  }
}

function _getPriceBigNum({ price }: { price: string }) {
  return new BigNumber(price);
}
export const getPriceBigNum = memoWeak(_getPriceBigNum);

export function getOppositeSide(side: Side) {
  return side === 'SELL' ? 'BUY' : 'SELL';
}
